import JSBI from 'jsbi'

// exports for external consumption
export type BigintIsh = JSBI | bigint | string

export const INITIAL_ALLOWED_SLIPPAGE = 50

export enum ChainId {
  MAINNET = 1,
  SEPOLIA = 11155111,
  SN_MAIN = -1,
  SN_SEPOLIA = -11155111,
  BSC_TESTNET = 97,
  VICTION_TESTNET = 89,
  VICTION_MAINNET = 88,
  SONIC_TESTNET = 64165,
  MINATO_SONEIUM = 1946,
  BASE_SEPOLIA = 84532,
  UNICHAIN_SEPOLIA = 1301,
  AURORA_TESTNET = 1313161555,
  METIS_MAINNET = 1088,
  TAIKO_TESTNET = 167009,
  BOBA_TESTNET = 28882,
  NEOX_MAINNET = 47763,
  U2U_MAINNET = 39
}

export const ChainIdHex: any = {
  [ChainId.MAINNET]: '0x1',
  [ChainId.SEPOLIA]: '0xaa36a7',
  [ChainId.BSC_TESTNET]: '0x61',
  [ChainId.VICTION_TESTNET]: '0x59',
  [ChainId.VICTION_MAINNET]: '0x58',
  [ChainId.SONIC_TESTNET]: '0xFAA5',
  [ChainId.MINATO_SONEIUM]: '0x79A',
  [ChainId.BASE_SEPOLIA]: '0x14a34',
  [ChainId.UNICHAIN_SEPOLIA]: '0x515',
  [ChainId.AURORA_TESTNET]: '0x4e454153',
  [ChainId.METIS_MAINNET]: '0x440',
  [ChainId.TAIKO_TESTNET]: '0x28c61',
  [ChainId.BOBA_TESTNET]: '0x70d2',
  [ChainId.NEOX_MAINNET]: '0xba93',
  [ChainId.U2U_MAINNET]: '0x27'
}

export enum TradeType {
  EXACT_INPUT,
  EXACT_OUTPUT
}

export enum Rounding {
  ROUND_DOWN,
  ROUND_HALF_UP,
  ROUND_UP
}

export const FACTORY_ADDRESS = {
  [ChainId.MAINNET]: '0xD705B4e18055D8Fa1d099d0533163a9e8fA09E4A',
  [ChainId.SEPOLIA]: '0x782783378a9D3BCCC8d9A03F5ED452263758a571',
  [ChainId.SN_MAIN]: '',
  [ChainId.SN_SEPOLIA]:
    '0x05d789e22a62125d58773cd899e1b609b476b5daa0c86dccb32c72836dcec906',
  [ChainId.BSC_TESTNET]: '0xdd8C69C093B34e886726Cd3E40D24445E272B684',
  [ChainId.VICTION_TESTNET]: '0x782783378a9D3BCCC8d9A03F5ED452263758a571',
  [ChainId.VICTION_MAINNET]: '0xa80258Eea4BA0865610eb239045737D08929c40b',
  [ChainId.SONIC_TESTNET]: '0x7cb2CF3dde3d518b408ea0B83738C067EEdb571D',
  [ChainId.MINATO_SONEIUM]: '0x0e63FffdB5d9Db4a3595Dc9F87cB6BBc4789fF9e',
  [ChainId.BASE_SEPOLIA]: '0x86d86dD68a2D7FD82de9760D447f1Ef11644B535',
  [ChainId.UNICHAIN_SEPOLIA]: '0x86d86dD68a2D7FD82de9760D447f1Ef11644B535',
  [ChainId.AURORA_TESTNET]: '0x86d86dd68a2d7fd82de9760d447f1ef11644b535',
  [ChainId.METIS_MAINNET]: '0x797691C82093Fe9EfAD6ceAcB1FC3080C2a4F85A',
  [ChainId.TAIKO_TESTNET]: '0x86d86dD68a2D7FD82de9760D447f1Ef11644B535',
  [ChainId.BOBA_TESTNET]: '0x7cb2CF3dde3d518b408ea0B83738C067EEdb571D',
  [ChainId.NEOX_MAINNET]: '0x40c2Be0C8c421235BDD93714BCac4ffB6A4067a5',
  [ChainId.U2U_MAINNET]: '0xb077EA75Bf218bf028AcEEBe61AEA4c4Ad85f62c'
}

export const INIT_CODE_HASH = {
  [ChainId.MAINNET]:
    '0xa92e1262e78c2029fb68aa25cd33df22da5c26a36d5ca3e7f82777add081632c',
  [ChainId.SEPOLIA]:
    '0xa65692b959243ffbbccaece1c8d7df4e24a4a318bc91af76c654277ae6cbf298',
  [ChainId.BSC_TESTNET]:
    '0xccd65ad557636196c3dbd40fffe1aab9c7d4e4d0b54ad1e60665ca595e186bac',
  [ChainId.VICTION_TESTNET]:
    '0x711312ac9a4efcfb1916d57f2ec6e03f5e1299e46f449ac9400da95ffc6d5a42',
  [ChainId.VICTION_MAINNET]:
    '0x330370b2bbf44605dd620805d2a2da4be4d6830689cdc295936cb2f1efb5e4af',
  [ChainId.SONIC_TESTNET]:
    '0x114d0544828b902794dd44ecddc6d536315d4f8ef581de0fcac30677b2b9e26b',
  [ChainId.MINATO_SONEIUM]:
    '0xf2eab90754ebe4f7948efc0065f1fec022ffaed2fff5c5048776cf8ce4701a19',
  [ChainId.BASE_SEPOLIA]:
    '0x330370b2bbf44605dd620805d2a2da4be4d6830689cdc295936cb2f1efb5e4af',
  [ChainId.UNICHAIN_SEPOLIA]:
    '0x330370b2bbf44605dd620805d2a2da4be4d6830689cdc295936cb2f1efb5e4af',
  [ChainId.AURORA_TESTNET]:
    '0x330370b2bbf44605dd620805d2a2da4be4d6830689cdc295936cb2f1efb5e4af',
  [ChainId.METIS_MAINNET]:
    '0x330370b2bbf44605dd620805d2a2da4be4d6830689cdc295936cb2f1efb5e4af',
  [ChainId.TAIKO_TESTNET]:
    '0x330370b2bbf44605dd620805d2a2da4be4d6830689cdc295936cb2f1efb5e4af',
  [ChainId.BOBA_TESTNET]:
    '0x330370b2bbf44605dd620805d2a2da4be4d6830689cdc295936cb2f1efb5e4af',
  [ChainId.NEOX_MAINNET]:
    '0x330370b2bbf44605dd620805d2a2da4be4d6830689cdc295936cb2f1efb5e4af',
  [ChainId.U2U_MAINNET]:
    '0x330370b2bbf44605dd620805d2a2da4be4d6830689cdc295936cb2f1efb5e4af'
}

export const MINIMUM_LIQUIDITY = JSBI.BigInt(1000)

// exports for internal consumption
export const ZERO = JSBI.BigInt(0)
export const ONE = JSBI.BigInt(1)
export const TWO = JSBI.BigInt(2)
export const THREE = JSBI.BigInt(3)
export const FIVE = JSBI.BigInt(5)
export const TEN = JSBI.BigInt(10)
export const _100 = JSBI.BigInt(100)
export const _997 = JSBI.BigInt(997)
export const _1000 = JSBI.BigInt(1000)

export enum SolidityType {
  uint8 = 'uint8',
  uint256 = 'uint256'
}

export const SOLIDITY_TYPE_MAXIMA = {
  [SolidityType.uint8]: JSBI.BigInt('0xff'),
  [SolidityType.uint256]: JSBI.BigInt(
    '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
  )
}
