import { TransactionResponse, Web3Provider } from '@ethersproject/providers'
import { BigNumber } from '@ethersproject/bignumber'
import {
  calculateGasMargin,
  calculateSlippageAmount,
  getRouterContract
} from '../utils/utils'
import {
  ChainId,
  Currency,
  CurrencyAmount,
  ETHER,
  Pair,
  Percent,
  Token,
  TokenAmount
} from '../sdk'
import { ApprovalState, Field, ROUTER_ADDRESS } from '../constants/constants'
import { wrappedCurrency } from '../utils/wrappedCurrency'
import { AccountInterface, Contract } from 'starknet'
import ISNRouter from '../abis/ISNRouter.json'

export const addLiquidityStarket = async (
  chainId: ChainId,
  library: AccountInterface | null | undefined,
  account: string | null | undefined,
  parsedAmountA: CurrencyAmount | undefined,
  parsedAmountB: CurrencyAmount | undefined,
  deadline: BigNumber | undefined
): Promise<TransactionResponse | undefined> => {
  try {
    if (!account || !library || !parsedAmountA || !parsedAmountB || !deadline)
      return

    const routerContract = new Contract(
      ISNRouter,
      ROUTER_ADDRESS[chainId],
      library
    )
    const currencyA = parsedAmountA?.currency
    const currencyB = parsedAmountB?.currency
    const addLiquidityCall = routerContract.populate('add_liquidity', {
      tokenA: wrappedCurrency(currencyA, chainId)?.address ?? '',
      tokenB: wrappedCurrency(currencyB, chainId)?.address ?? '',
      stable: false,
      feeTier: 0,
      amountADesired: parsedAmountA.raw.toString(),
      amountBDesired: parsedAmountB.raw.toString(),
      amountAMin: 0,
      amountBMin: 0,
      to: account,
      deadline
    })

    const tx = await library.execute([addLiquidityCall])

    return {
      hash: tx.transaction_hash
    } as TransactionResponse
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function addLiquidity(
  chainId: ChainId | undefined,
  library: Web3Provider | AccountInterface | undefined,
  account: string | null | undefined,
  parsedAmountA: CurrencyAmount | undefined,
  parsedAmountB: CurrencyAmount | undefined,
  deadline: BigNumber | undefined,
  noLiquidity: boolean | undefined,
  allowedSlippage: number
): Promise<TransactionResponse | undefined> {
  if (!chainId || !library || !account) return

  if (chainId === ChainId.SN_SEPOLIA || chainId === ChainId.SN_MAIN) {
    return addLiquidityStarket(
      chainId,
      library as AccountInterface,
      account,
      parsedAmountA,
      parsedAmountB,
      deadline
    )
  }
  const router = getRouterContract(chainId, library as Web3Provider, account)
  const currencyA = parsedAmountA?.currency
  const currencyB = parsedAmountB?.currency

  if (
    !parsedAmountA ||
    !parsedAmountB ||
    !currencyA ||
    !currencyB ||
    !deadline
  ) {
    return
  }

  const amountsMin = {
    [Field.CURRENCY_A]: calculateSlippageAmount(
      parsedAmountA,
      noLiquidity ? 0 : allowedSlippage
    )[0],
    [Field.CURRENCY_B]: calculateSlippageAmount(
      parsedAmountB,
      noLiquidity ? 0 : allowedSlippage
    )[0]
  }

  let estimate,
    method: (...args: any) => Promise<TransactionResponse>,
    args: Array<string | string[] | number>,
    value: BigNumber | null
  if (currencyA === ETHER || currencyB === ETHER) {
    const tokenBIsETH = currencyB === ETHER
    estimate = router.estimateGas.addLiquidityETH
    method = router.addLiquidityETH
    args = [
      wrappedCurrency(tokenBIsETH ? currencyA : currencyB, chainId)?.address ??
        '', // token
      (tokenBIsETH ? parsedAmountA : parsedAmountB).raw.toString(), // token desired
      amountsMin[tokenBIsETH ? Field.CURRENCY_A : Field.CURRENCY_B].toString(), // token min
      amountsMin[tokenBIsETH ? Field.CURRENCY_B : Field.CURRENCY_A].toString(), // eth min
      account,
      deadline.toHexString()
    ]
    value = BigNumber.from(
      (tokenBIsETH ? parsedAmountB : parsedAmountA).raw.toString()
    )
  } else {
    estimate = router.estimateGas.addLiquidity
    method = router.addLiquidity
    args = [
      wrappedCurrency(currencyA, chainId)?.address ?? '',
      wrappedCurrency(currencyB, chainId)?.address ?? '',
      parsedAmountA.raw.toString(),
      parsedAmountB.raw.toString(),
      amountsMin[Field.CURRENCY_A].toString(),
      amountsMin[Field.CURRENCY_B].toString(),
      account,
      deadline.toHexString()
    ]
    value = null
  }

  return await estimate(...args, value ? { value } : {}).then(
    (estimatedGasLimit) =>
      method(...args, {
        ...(value ? { value } : {}),
        gasLimit: calculateGasMargin(estimatedGasLimit)
      }).then((response) => {
        return response
      })
  )
}

export type ParsedAmounts = {
  LIQUIDITY_PERCENT: Percent
  LIQUIDITY?: TokenAmount
  CURRENCY_A?: CurrencyAmount
  CURRENCY_B?: CurrencyAmount
  currencyA: Currency | undefined
  currencyB: Currency | undefined
}

export type SignatureData = {
  v: number
  r: string
  s: string
  deadline: number
}

const removeLiquidityStarknet = async (
  chainId: ChainId,
  account: string | null | undefined,
  library: AccountInterface | null | undefined,
  removeAmount: any,
  tokenA: Token | undefined,
  tokenB: Token | undefined,
  deadline: BigNumber | undefined
) => {
  try {
    if (!account || !library || !removeAmount || !tokenA || !tokenB)
      throw new Error('missing dependencies')

    const routerContract = new Contract(
      ISNRouter,
      ROUTER_ADDRESS[chainId],
      library
    )

    const addLiquidityCall = routerContract.populate('remove_liquidity', {
      tokenA: tokenA.address || '',
      tokenB: tokenB.address || '',
      stable: false,
      feeTier: 0,
      liquidity: removeAmount,
      amountAMin: 0,
      amountBMin: 0,
      to: account,
      deadline
    } as any)

    const tx = await library.execute([addLiquidityCall])

    // await library.waitForTransaction(tx.transaction_hash)

    return { hash: tx.transaction_hash }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function removeLiquidity(
  chainId: ChainId | undefined,
  library: Web3Provider | AccountInterface | undefined,
  account: string | null | undefined,
  parsedAmounts: ParsedAmounts,
  deadline: BigNumber | undefined,
  allowedSlippage: number,
  approval: ApprovalState,
  signatureData: SignatureData | null
) {
  if (!chainId || !library || !account || !deadline)
    throw new Error('missing dependencies')

  const {
    [Field.CURRENCY_A]: currencyAmountA,
    [Field.CURRENCY_B]: currencyAmountB,
    currencyA,
    currencyB
  } = parsedAmounts

  const liquidityAmount = parsedAmounts[Field.LIQUIDITY]

  if (!liquidityAmount) throw new Error('missing liquidity amount')

  if (!currencyAmountA || !currencyAmountB) {
    throw new Error('missing currency amounts')
  }

  const tokenA = wrappedCurrency(currencyA, chainId)
  const tokenB = wrappedCurrency(currencyB, chainId)

  if (chainId === ChainId.SN_MAIN || chainId === ChainId.SN_SEPOLIA) {
    return removeLiquidityStarknet(
      chainId,
      account,
      library as AccountInterface,
      liquidityAmount.raw.toString(),
      tokenA,
      tokenB,
      deadline
    )
  }

  const router = getRouterContract(chainId, library as Web3Provider, account)

  const amountsMin = {
    [Field.CURRENCY_A]: calculateSlippageAmount(
      currencyAmountA,
      allowedSlippage
    )[0],
    [Field.CURRENCY_B]: calculateSlippageAmount(
      currencyAmountB,
      allowedSlippage
    )[0]
  }

  if (!currencyA || !currencyB) throw new Error('missing tokens')

  const currencyBIsETH = currencyB === ETHER
  const oneCurrencyIsETH = currencyA === ETHER || currencyBIsETH

  if (!tokenA || !tokenB) throw new Error('could not wrap')

  let methodNames: string[], args: Array<string | string[] | number | boolean>
  // we have approval, use normal remove liquidity
  if (approval === ApprovalState.APPROVED) {
    // removeLiquidityETH
    if (oneCurrencyIsETH) {
      methodNames = [
        'removeLiquidityETH',
        'removeLiquidityETHSupportingFeeOnTransferTokens'
      ]
      args = [
        currencyBIsETH ? tokenA.address : tokenB.address,
        liquidityAmount.raw.toString(),
        amountsMin[
          currencyBIsETH ? Field.CURRENCY_A : Field.CURRENCY_B
        ].toString(),
        amountsMin[
          currencyBIsETH ? Field.CURRENCY_B : Field.CURRENCY_A
        ].toString(),
        account,
        deadline.toHexString()
      ]
    }
    // removeLiquidity
    else {
      methodNames = ['removeLiquidity']
      args = [
        tokenA.address,
        tokenB.address,
        liquidityAmount.raw.toString(),
        amountsMin[Field.CURRENCY_A].toString(),
        amountsMin[Field.CURRENCY_B].toString(),
        account,
        deadline.toHexString()
      ]
    }
  }
  // we have a signataure, use permit versions of remove liquidity
  else if (signatureData !== null) {
    // removeLiquidityETHWithPermit
    if (oneCurrencyIsETH) {
      methodNames = [
        'removeLiquidityETHWithPermit',
        'removeLiquidityETHWithPermitSupportingFeeOnTransferTokens'
      ]
      args = [
        currencyBIsETH ? tokenA.address : tokenB.address,
        liquidityAmount.raw.toString(),
        amountsMin[
          currencyBIsETH ? Field.CURRENCY_A : Field.CURRENCY_B
        ].toString(),
        amountsMin[
          currencyBIsETH ? Field.CURRENCY_B : Field.CURRENCY_A
        ].toString(),
        account,
        signatureData.deadline,
        false,
        signatureData.v,
        signatureData.r,
        signatureData.s
      ]
    }
    // removeLiquidityETHWithPermit
    else {
      methodNames = ['removeLiquidityWithPermit']
      args = [
        tokenA.address,
        tokenB.address,
        liquidityAmount.raw.toString(),
        amountsMin[Field.CURRENCY_A].toString(),
        amountsMin[Field.CURRENCY_B].toString(),
        account,
        signatureData.deadline,
        false,
        signatureData.v,
        signatureData.r,
        signatureData.s
      ]
    }
  } else {
    throw new Error(
      'Attempting to confirm without approval or a signature. Please contact support.'
    )
  }

  const safeGasEstimates: (BigNumber | undefined)[] = await Promise.all(
    methodNames.map((methodName) =>
      router.estimateGas[methodName](...args)
        .then(calculateGasMargin)
        .catch((error) => {
          console.error(`estimateGas failed`, methodName, args, error)
          return undefined
        })
    )
  )

  const indexOfSuccessfulEstimation = safeGasEstimates.findIndex(
    (safeGasEstimate) => BigNumber.isBigNumber(safeGasEstimate)
  )

  // all estimations failed...
  if (indexOfSuccessfulEstimation === -1) {
    console.error('This transaction would fail. Please contact support.')
  } else {
    const methodName = methodNames[indexOfSuccessfulEstimation]
    const safeGasEstimate = safeGasEstimates[indexOfSuccessfulEstimation]

    return await router[methodName](...args, {
      gasLimit: safeGasEstimate
    })
      .then((response: TransactionResponse) => {
        return response
      })
      .catch((e: Error) => {
        console.log(e)
        return null
      })
  }
}

/**
 * Given two tokens return the liquidity token that represents its liquidity shares
 * @param tokenA one of the two tokens
 * @param tokenB the other token
 */
export function toV2LiquidityToken([tokenA, tokenB]: [Token, Token]): Token {
  return new Token(
    tokenA.chainId,
    Pair.getAddress(tokenA, tokenB),
    18,
    'UNI-V2',
    'Uniswap V2'
  )
}
