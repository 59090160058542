import { Price } from './fractions/price'
import { TokenAmount } from './fractions/tokenAmount'
import invariant from 'tiny-invariant'
import JSBI from 'jsbi'
import { pack, keccak256 } from '@ethersproject/solidity'
import { getCreate2Address } from '@ethersproject/address'

import {
  BigintIsh,
  FACTORY_ADDRESS,
  INIT_CODE_HASH,
  MINIMUM_LIQUIDITY,
  ZERO,
  ONE,
  FIVE,
  _997,
  _1000,
  ChainId
} from '../constants'
import { sqrt, parseBigintIsh } from '../utils'
import {
  InsufficientReservesError,
  InsufficientInputAmountError
} from '../errors'
import { Token } from './token'
import Web3 from 'web3'
import {
  PYTH_ADDRESS,
  ROUTER_ADDRESS,
  ROUTER_ADDRESS_WITH_PRICE,
  RPC_URLS
} from '../../constants/constants'
import IRouter from '../../abis/IRouter.json'
import IRouterWithPrice from '../../abis/IRouterWithPrice.json'
import IPair from '../../abis/IPair.json'
import IAggregator from '../../abis/IAggregator.json'
import IPythPriceFeed from '../../abis/IPythPriceFeed.json'
import axios from 'axios'
import { Buffer } from 'buffer'

let PAIR_ADDRESS_CACHE: {
  [token0Address: string]: { [token1Address: string]: string }
} = {}

async function getLatestPrice(basePriceIds: string, quotePriceIds: string) {
  const ids = []
  if (basePriceIds !== ZERO_ADDRESS) {
    ids.push(basePriceIds)
  }
  if (quotePriceIds !== ZERO_ADDRESS) {
    ids.push(quotePriceIds)
  }

  const response = await axios.get(
    'https://hermes.pyth.network/v2/updates/price/latest',
    {
      params: {
        ids
      }
    }
  )
  return response.data
}

export const ZERO_ADDRESS =
  '0x0000000000000000000000000000000000000000000000000000000000000000'

async function getPriceFeedUpdateData(
  basePriceIds: string,
  quotePriceIds: string
) {
  const ids = []
  if (basePriceIds !== ZERO_ADDRESS) {
    ids.push(basePriceIds)
  }
  if (quotePriceIds !== ZERO_ADDRESS) {
    ids.push(quotePriceIds)
  }
  const response = await axios.get(
    'https://hermes.pyth.network/api/latest_vaas',
    {
      params: {
        ids
      }
    }
  )
  return response.data.map(
    (vaa: any) => '0x' + Buffer.from(vaa, 'base64').toString('hex')
  )
}
export class Pair {
  public readonly liquidityToken: Token
  private readonly tokenAmounts: [TokenAmount, TokenAmount]

  public static getAddress(tokenA: Token, tokenB: Token): string {
    const tokens = tokenA.sortsBefore(tokenB)
      ? [tokenA, tokenB]
      : [tokenB, tokenA] // does safety checks

    if (
      PAIR_ADDRESS_CACHE?.[tokens[0].address]?.[tokens[1].address] === undefined
    ) {
      PAIR_ADDRESS_CACHE = {
        ...PAIR_ADDRESS_CACHE,
        [tokens[0].address]: {
          ...PAIR_ADDRESS_CACHE?.[tokens[0].address],
          [tokens[1].address]: getCreate2Address(
            FACTORY_ADDRESS[tokenA.chainId],
            keccak256(
              ['bytes'],
              [
                pack(
                  ['address', 'address'],
                  [tokens[0].address, tokens[1].address]
                )
              ]
            ),
            INIT_CODE_HASH[tokenA.chainId]
          )
        }
      }
    }

    return PAIR_ADDRESS_CACHE[tokens[0].address][tokens[1].address]
  }

  public constructor(tokenAmountA: TokenAmount, tokenAmountB: TokenAmount) {
    const tokenAmounts = tokenAmountA.token.sortsBefore(tokenAmountB.token) // does safety checks
      ? [tokenAmountA, tokenAmountB]
      : [tokenAmountB, tokenAmountA]
    this.liquidityToken = new Token(
      tokenAmounts[0].token.chainId,
      Pair.getAddress(tokenAmounts[0].token, tokenAmounts[1].token),
      18,
      'BRF-V1',
      'BrownFI V1'
    )
    this.tokenAmounts = tokenAmounts as [TokenAmount, TokenAmount]
  }

  /**
   * Returns true if the token is either token0 or token1
   * @param token to check
   */
  public involvesToken(token: Token): boolean {
    return token.equals(this.token0) || token.equals(this.token1)
  }

  /**
   * Returns the current mid price of the pair in terms of token0, i.e. the ratio of reserve1 to reserve0
   */
  public get token0Price(): Price {
    return new Price(
      this.token0,
      this.token1,
      this.tokenAmounts[0].raw,
      this.tokenAmounts[1].raw
    )
  }

  /**
   * Returns the current mid price of the pair in terms of token1, i.e. the ratio of reserve0 to reserve1
   */
  public get token1Price(): Price {
    return new Price(
      this.token1,
      this.token0,
      this.tokenAmounts[1].raw,
      this.tokenAmounts[0].raw
    )
  }

  /**
   * Return the price of the given token in terms of the other token in the pair.
   * @param token token to return price of
   */
  public priceOf(token: Token): Price {
    invariant(this.involvesToken(token), 'TOKEN')
    return token.equals(this.token0) ? this.token0Price : this.token1Price
  }

  /**
   * Returns the chain ID of the tokens in the pair.
   */
  public get chainId(): ChainId {
    return this.token0.chainId
  }

  public get token0(): Token {
    return this.tokenAmounts[0].token
  }

  public get token1(): Token {
    return this.tokenAmounts[1].token
  }

  public get reserve0(): TokenAmount {
    return this.tokenAmounts[0]
  }

  public get reserve1(): TokenAmount {
    return this.tokenAmounts[1]
  }

  public reserveOf(token: Token): TokenAmount {
    invariant(this.involvesToken(token), 'TOKEN')
    return token.equals(this.token0) ? this.reserve0 : this.reserve1
  }

  public getOutputAmount(inputAmount: TokenAmount): [TokenAmount, Pair] {
    invariant(this.involvesToken(inputAmount.token), 'TOKEN')
    if (
      JSBI.equal(this.reserve0.raw, ZERO) ||
      JSBI.equal(this.reserve1.raw, ZERO)
    ) {
      throw new InsufficientReservesError()
    }
    const inputReserve = this.reserveOf(inputAmount.token)
    const outputReserve = this.reserveOf(
      inputAmount.token.equals(this.token0) ? this.token1 : this.token0
    )
    const inputAmountWithFee = JSBI.multiply(inputAmount.raw, _997)
    const numerator = JSBI.multiply(inputAmountWithFee, outputReserve.raw)
    const denominator = JSBI.add(
      JSBI.multiply(inputReserve.raw, _1000),
      inputAmountWithFee
    )
    const outputAmount = new TokenAmount(
      inputAmount.token.equals(this.token0) ? this.token1 : this.token0,
      JSBI.divide(numerator, denominator)
    )
    if (JSBI.equal(outputAmount.raw, ZERO)) {
      throw new InsufficientInputAmountError()
    }
    return [
      outputAmount,
      new Pair(
        inputReserve.add(inputAmount),
        outputReserve.subtract(outputAmount)
      )
    ]
  }

  public async getOutputAmountAsync(
    inputAmount: TokenAmount,
    path: Token[],
    chainId: ChainId,
    pairAddress: string,
    account: string
  ): Promise<[TokenAmount, Pair, number, number, string[], number]> {
    invariant(this.involvesToken(inputAmount.token), 'TOKEN')
    if (
      JSBI.equal(this.reserve0.raw, ZERO) ||
      JSBI.equal(this.reserve1.raw, ZERO)
    ) {
      throw new InsufficientReservesError()
    }
    const inputReserve = this.reserveOf(inputAmount.token)
    const outputReserve = this.reserveOf(
      inputAmount.token.equals(this.token0) ? this.token1 : this.token0
    )

    const web3 = new Web3(new Web3.providers.HttpProvider(RPC_URLS[chainId]))
    const routerContract = new web3.eth.Contract(
      IRouter as any,
      ROUTER_ADDRESS[chainId]
    )
    const routerContractWithPrice = new web3.eth.Contract(
      IRouterWithPrice as any,
      ROUTER_ADDRESS_WITH_PRICE[chainId]
    )

    const pairContract = new web3.eth.Contract(IPair as any, pairAddress)

    const [priceFeedAddress, qti] = await Promise.all([
      pairContract.methods.priceFeed().call(),
      pairContract.methods.qti().call()
    ])

    // const k = await pairContract.methods.kappa().call()

    const priceFeedContract = new web3.eth.Contract(
      IAggregator as any,
      priceFeedAddress
    )

    let basePriceIds = ''
    let quotePriceIds = ''
    let priceFeedUpdateData
    let updateFee = 0

    if (
      chainId === ChainId.VICTION_MAINNET ||
      chainId === ChainId.SONIC_TESTNET ||
      chainId === ChainId.AURORA_TESTNET ||
      chainId === ChainId.TAIKO_TESTNET ||
      chainId === ChainId.BOBA_TESTNET
    ) {
      const pythPriceFeedContract = new web3.eth.Contract(
        IPythPriceFeed as any,
        priceFeedAddress
      )

      const [baseTokenPriceId, quoteTokenPriceId] = await Promise.all([
        pythPriceFeedContract.methods.baseTokenPriceId().call(),
        pythPriceFeedContract.methods.quoteTokenPriceId().call()
      ])

      basePriceIds = baseTokenPriceId
      quotePriceIds = quoteTokenPriceId
      priceFeedUpdateData = await getPriceFeedUpdateData(
        basePriceIds,
        quotePriceIds
      )
      const pythABI = [
        {
          inputs: [
            { internalType: 'bytes[]', name: 'updateData', type: 'bytes[]' }
          ],
          name: 'getUpdateFee',
          outputs: [
            { internalType: 'uint256', name: 'feeAmount', type: 'uint256' }
          ],
          stateMutability: 'view',
          type: 'function'
        }
      ]
      const pythContract = new web3.eth.Contract(
        pythABI as any,
        PYTH_ADDRESS[chainId]
      )
      updateFee = await pythContract.methods
        .getUpdateFee(priceFeedUpdateData)
        .call()
    }

    const [balances, latestRound, decimals, latestPrice] = await Promise.all([
      chainId === ChainId.VICTION_MAINNET ||
      chainId === ChainId.SONIC_TESTNET ||
      chainId === ChainId.AURORA_TESTNET ||
      chainId === ChainId.TAIKO_TESTNET ||
      chainId === ChainId.BOBA_TESTNET
        ? routerContractWithPrice.methods
            .getAmountsOutWithPrice(
              inputAmount.raw.toString(),
              path?.map((token) => token.address),
              priceFeedUpdateData
            )
            .call({
              value: updateFee,
              from: account
            })
        : routerContract.methods
            .getAmountsOut(
              inputAmount.raw.toString(),
              path?.map((token) => token.address)
            )
            .call(),
      chainId !== ChainId.VICTION_MAINNET &&
        chainId !== ChainId.SONIC_TESTNET &&
        chainId !== ChainId.AURORA_TESTNET &&
        chainId !== ChainId.TAIKO_TESTNET &&
        chainId !== ChainId.BOBA_TESTNET &&
        priceFeedContract.methods.latestRoundData().call(),
      chainId !== ChainId.VICTION_MAINNET &&
        chainId !== ChainId.SONIC_TESTNET &&
        chainId !== ChainId.AURORA_TESTNET &&
        chainId !== ChainId.TAIKO_TESTNET &&
        chainId !== ChainId.BOBA_TESTNET &&
        priceFeedContract.methods.decimals().call(),
      (chainId === ChainId.VICTION_MAINNET ||
        chainId === ChainId.SONIC_TESTNET ||
        chainId === ChainId.AURORA_TESTNET ||
        chainId === ChainId.TAIKO_TESTNET ||
        chainId === ChainId.BOBA_TESTNET) &&
        getLatestPrice(basePriceIds, quotePriceIds)
    ])

    let basePrice: number = 1
    let quotePrice: number = 0

    latestPrice?.parsed?.forEach((item: any) => {
      if (`0x${item?.id?.toLowerCase()}` === basePriceIds?.toLowerCase()) {
        basePrice = +item?.price?.price
      }
      if (`0x${item?.id?.toLowerCase()}` === quotePriceIds?.toLowerCase()) {
        quotePrice = +item?.price?.price
      }
    })

    const odPrice =
      chainId === ChainId.VICTION_MAINNET ||
      chainId === ChainId.SONIC_TESTNET ||
      chainId === ChainId.AURORA_TESTNET ||
      chainId === ChainId.TAIKO_TESTNET ||
      chainId === ChainId.BOBA_TESTNET
        ? qti === 1
          ? basePrice / (quotePrice || 10 ** 8)
          : (quotePrice || 10 ** 8) / basePrice
        : +latestRound.answer / 10 ** +decimals

    const outputAmount = new TokenAmount(
      inputAmount.token.equals(this.token0) ? this.token1 : this.token0,
      balances?.[1]
    )
    if (JSBI.equal(outputAmount.raw, ZERO)) {
      throw new InsufficientInputAmountError()
    }
    return [
      outputAmount,
      new Pair(
        inputReserve.add(inputAmount),
        outputReserve.subtract(outputAmount)
      ),
      odPrice,
      +qti,
      priceFeedUpdateData,
      updateFee
    ]
  }

  public getInputAmount(outputAmount: TokenAmount): [TokenAmount, Pair] {
    invariant(this.involvesToken(outputAmount.token), 'TOKEN')
    if (
      JSBI.equal(this.reserve0.raw, ZERO) ||
      JSBI.equal(this.reserve1.raw, ZERO) ||
      JSBI.greaterThanOrEqual(
        outputAmount.raw,
        this.reserveOf(outputAmount.token).raw
      )
    ) {
      throw new InsufficientReservesError()
    }

    const outputReserve = this.reserveOf(outputAmount.token)
    const inputReserve = this.reserveOf(
      outputAmount.token.equals(this.token0) ? this.token1 : this.token0
    )
    const numerator = JSBI.multiply(
      JSBI.multiply(inputReserve.raw, outputAmount.raw),
      _1000
    )
    const denominator = JSBI.multiply(
      JSBI.subtract(outputReserve.raw, outputAmount.raw),
      _997
    )
    const inputAmount = new TokenAmount(
      outputAmount.token.equals(this.token0) ? this.token1 : this.token0,
      JSBI.add(JSBI.divide(numerator, denominator), ONE)
    )
    return [
      inputAmount,
      new Pair(
        inputReserve.add(inputAmount),
        outputReserve.subtract(outputAmount)
      )
    ]
  }

  public async getInputAmountAsync(
    outputAmount: TokenAmount,
    path: Token[],
    chainId: ChainId,
    pairAddress: string,
    account: string
  ): Promise<[TokenAmount, Pair, number, number, string[], number]> {
    invariant(this.involvesToken(outputAmount.token), 'TOKEN')
    if (
      JSBI.equal(this.reserve0.raw, ZERO) ||
      JSBI.equal(this.reserve1.raw, ZERO) ||
      JSBI.greaterThanOrEqual(
        outputAmount.raw,
        this.reserveOf(outputAmount.token).raw
      )
    ) {
      throw new InsufficientReservesError()
    }

    const outputReserve = this.reserveOf(outputAmount.token)
    const inputReserve = this.reserveOf(
      outputAmount.token.equals(this.token0) ? this.token1 : this.token0
    )

    const web3 = new Web3(new Web3.providers.HttpProvider(RPC_URLS[chainId]))
    const routerContract = new web3.eth.Contract(
      IRouter as any,
      ROUTER_ADDRESS[chainId]
    )
    const routerContractWithPrice = new web3.eth.Contract(
      IRouterWithPrice as any,
      ROUTER_ADDRESS_WITH_PRICE[chainId]
    )
    const pairContract = new web3.eth.Contract(IPair as any, pairAddress)

    const [priceFeedAddress, qti] = await Promise.all([
      pairContract.methods.priceFeed().call(),
      pairContract.methods.qti().call()
    ])

    const priceFeedContract = new web3.eth.Contract(
      IAggregator as any,
      priceFeedAddress
    )

    let basePriceIds = ''
    let quotePriceIds = ''
    let priceFeedUpdateData
    let updateFee = 0
    if (
      chainId === ChainId.VICTION_MAINNET ||
      chainId === ChainId.SONIC_TESTNET ||
      chainId === ChainId.AURORA_TESTNET ||
      chainId === ChainId.TAIKO_TESTNET ||
      chainId === ChainId.BOBA_TESTNET
    ) {
      const pythPriceFeedContract = new web3.eth.Contract(
        IPythPriceFeed as any,
        priceFeedAddress
      )

      const [baseTokenPriceId, quoteTokenPriceId] = await Promise.all([
        pythPriceFeedContract.methods.baseTokenPriceId().call(),
        pythPriceFeedContract.methods.quoteTokenPriceId().call()
      ])

      basePriceIds = baseTokenPriceId
      quotePriceIds = quoteTokenPriceId
      priceFeedUpdateData = await getPriceFeedUpdateData(
        basePriceIds,
        quotePriceIds
      )
      const pythABI = [
        {
          inputs: [
            { internalType: 'bytes[]', name: 'updateData', type: 'bytes[]' }
          ],
          name: 'getUpdateFee',
          outputs: [
            { internalType: 'uint256', name: 'feeAmount', type: 'uint256' }
          ],
          stateMutability: 'view',
          type: 'function'
        }
      ]
      const pythContract = new web3.eth.Contract(
        pythABI as any,
        PYTH_ADDRESS[chainId]
      )
      updateFee = await pythContract.methods
        .getUpdateFee(priceFeedUpdateData)
        .call()
    }

    const [balances, latestRound, decimals, latestPrice] = await Promise.all([
      chainId === ChainId.VICTION_MAINNET ||
      chainId === ChainId.SONIC_TESTNET ||
      chainId === ChainId.AURORA_TESTNET ||
      chainId === ChainId.TAIKO_TESTNET ||
      chainId === ChainId.BOBA_TESTNET
        ? routerContractWithPrice.methods
            .getAmountsInWithPrice(
              outputAmount.raw.toString(),
              path?.map((token) => token.address),
              priceFeedUpdateData
            )
            .call({
              value: updateFee,
              from: account
            })
        : routerContract.methods
            .getAmountsIn(
              outputAmount.raw.toString(),
              path?.map((token) => token.address)
            )
            .call(),
      chainId !== ChainId.VICTION_MAINNET &&
        chainId !== ChainId.SONIC_TESTNET &&
        chainId !== ChainId.AURORA_TESTNET &&
        chainId !== ChainId.TAIKO_TESTNET &&
        chainId !== ChainId.BOBA_TESTNET &&
        priceFeedContract.methods.latestRoundData().call(),
      chainId !== ChainId.VICTION_MAINNET &&
        chainId !== ChainId.SONIC_TESTNET &&
        chainId !== ChainId.AURORA_TESTNET &&
        chainId !== ChainId.TAIKO_TESTNET &&
        chainId !== ChainId.BOBA_TESTNET &&
        priceFeedContract.methods.decimals().call(),
      (chainId === ChainId.VICTION_MAINNET ||
        chainId === ChainId.SONIC_TESTNET ||
        chainId === ChainId.AURORA_TESTNET ||
        chainId === ChainId.TAIKO_TESTNET ||
        chainId === ChainId.BOBA_TESTNET) &&
        getLatestPrice(basePriceIds, quotePriceIds)
    ])

    let basePrice: number = 1
    let quotePrice: number = 0

    latestPrice?.parsed?.forEach((item: any) => {
      if (`0x${item?.id?.toLowerCase()}` === basePriceIds?.toLowerCase()) {
        basePrice = +item?.price?.price
      }
      if (`0x${item?.id?.toLowerCase()}` === quotePriceIds?.toLowerCase()) {
        quotePrice = +item?.price?.price
      }
    })

    const odPrice =
      chainId === ChainId.VICTION_MAINNET ||
      chainId === ChainId.SONIC_TESTNET ||
      chainId === ChainId.AURORA_TESTNET ||
      chainId === ChainId.TAIKO_TESTNET ||
      chainId === ChainId.BOBA_TESTNET
        ? qti === 1
          ? basePrice / (quotePrice || 10 ** 8)
          : (quotePrice || 10 ** 8) / basePrice
        : +latestRound.answer / 10 ** +decimals

    const inputAmount = new TokenAmount(
      outputAmount.token.equals(this.token0) ? this.token1 : this.token0,
      balances?.[0]
    )
    return [
      inputAmount,
      new Pair(
        inputReserve.add(inputAmount),
        outputReserve.subtract(outputAmount)
      ),
      odPrice,
      +qti,
      priceFeedUpdateData,
      updateFee
    ]
  }

  public getLiquidityMinted(
    totalSupply: TokenAmount,
    tokenAmountA: TokenAmount,
    tokenAmountB: TokenAmount
  ): TokenAmount {
    invariant(totalSupply.token.equals(this.liquidityToken), 'LIQUIDITY')
    const tokenAmounts = tokenAmountA.token.sortsBefore(tokenAmountB.token) // does safety checks
      ? [tokenAmountA, tokenAmountB]
      : [tokenAmountB, tokenAmountA]
    invariant(
      tokenAmounts[0].token.equals(this.token0) &&
        tokenAmounts[1].token.equals(this.token1),
      'TOKEN'
    )

    let liquidity: JSBI
    if (JSBI.equal(totalSupply.raw, ZERO)) {
      liquidity = JSBI.subtract(
        sqrt(JSBI.multiply(tokenAmounts[0].raw, tokenAmounts[1].raw)),
        MINIMUM_LIQUIDITY
      )
    } else {
      const amount0 = JSBI.divide(
        JSBI.multiply(tokenAmounts[0].raw, totalSupply.raw),
        this.reserve0.raw
      )
      const amount1 = JSBI.divide(
        JSBI.multiply(tokenAmounts[1].raw, totalSupply.raw),
        this.reserve1.raw
      )
      liquidity = JSBI.lessThanOrEqual(amount0, amount1) ? amount0 : amount1
    }
    if (!JSBI.greaterThan(liquidity, ZERO)) {
      throw new InsufficientInputAmountError()
    }
    return new TokenAmount(this.liquidityToken, liquidity)
  }

  public getLiquidityValue(
    token: Token,
    totalSupply: TokenAmount,
    liquidity: TokenAmount,
    feeOn: boolean = false,
    kLast?: BigintIsh
  ): TokenAmount {
    invariant(this.involvesToken(token), 'TOKEN')
    invariant(totalSupply.token.equals(this.liquidityToken), 'TOTAL_SUPPLY')
    invariant(liquidity.token.equals(this.liquidityToken), 'LIQUIDITY')
    invariant(JSBI.lessThanOrEqual(liquidity.raw, totalSupply.raw), 'LIQUIDITY')

    let totalSupplyAdjusted: TokenAmount
    if (!feeOn) {
      totalSupplyAdjusted = totalSupply
    } else {
      invariant(!!kLast, 'K_LAST')
      const kLastParsed = parseBigintIsh(kLast)
      if (!JSBI.equal(kLastParsed, ZERO)) {
        const rootK = sqrt(JSBI.multiply(this.reserve0.raw, this.reserve1.raw))
        const rootKLast = sqrt(kLastParsed)
        if (JSBI.greaterThan(rootK, rootKLast)) {
          const numerator = JSBI.multiply(
            totalSupply.raw,
            JSBI.subtract(rootK, rootKLast)
          )
          const denominator = JSBI.add(JSBI.multiply(rootK, FIVE), rootKLast)
          const feeLiquidity = JSBI.divide(numerator, denominator)
          totalSupplyAdjusted = totalSupply.add(
            new TokenAmount(this.liquidityToken, feeLiquidity)
          )
        } else {
          totalSupplyAdjusted = totalSupply
        }
      } else {
        totalSupplyAdjusted = totalSupply
      }
    }

    return new TokenAmount(
      token,
      JSBI.divide(
        JSBI.multiply(liquidity.raw, this.reserveOf(token).raw),
        totalSupplyAdjusted.raw
      )
    )
  }
}
