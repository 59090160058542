import invariant from 'tiny-invariant'

import { ChainId, ONE, TradeType, ZERO } from '../constants'
import { sortedInsert } from '../utils'
import { Currency, ETHER } from './currency'
import { CurrencyAmount } from './fractions/currencyAmount'
import { Fraction } from './fractions/fraction'
import { Percent } from './fractions/percent'
import { Price } from './fractions/price'
import { TokenAmount } from './fractions/tokenAmount'
import { Pair } from './pair'
import { Route } from './route'
import { currencyEquals, Token, WETH } from './token'

/**
 * Returns the percent difference between the mid price and the execution price, i.e. price impact.
 * @param midPrice mid price before the trade
 * @param inputAmount the input amount of the trade
 * @param outputAmount the output amount of the trade
 */
// function computePriceImpact(
//   midPrice: Price,
//   inputAmount: CurrencyAmount,
//   outputAmount: CurrencyAmount
// ): Percent {
//   const exactQuote = midPrice.raw.multiply(inputAmount.raw)
//   // calculate slippage := (exactQuote - outputAmount) / exactQuote
//   const slippage = exactQuote.subtract(outputAmount.raw).divide(exactQuote)
//   return new Percent(slippage.numerator, slippage.denominator)
// }

// minimal interface so the input output comparator may be shared across types
interface InputOutput {
  readonly inputAmount: CurrencyAmount
  readonly outputAmount: CurrencyAmount
}

// comparator function that allows sorting trades by their output amounts, in decreasing order, and then input amounts
// in increasing order. i.e. the best trades have the most outputs for the least inputs and are sorted first
export function inputOutputComparator(a: InputOutput, b: InputOutput): number {
  // must have same input and output token for comparison
  invariant(
    currencyEquals(a.inputAmount.currency, b.inputAmount.currency),
    'INPUT_CURRENCY'
  )
  invariant(
    currencyEquals(a.outputAmount.currency, b.outputAmount.currency),
    'OUTPUT_CURRENCY'
  )
  if (a.outputAmount.equalTo(b.outputAmount)) {
    if (a.inputAmount.equalTo(b.inputAmount)) {
      return 0
    }
    // trade A requires less input than trade B, so A should come first
    if (a.inputAmount.lessThan(b.inputAmount)) {
      return -1
    } else {
      return 1
    }
  } else {
    // tradeA has less output than trade B, so should come second
    if (a.outputAmount.lessThan(b.outputAmount)) {
      return 1
    } else {
      return -1
    }
  }
}

// extension of the input output comparator that also considers other dimensions of the trade in ranking them
export function tradeComparator(a: Trade, b: Trade) {
  if (
    !a.priceImpact ||
    !b.priceImpact ||
    !a.inputAmount ||
    !b.inputAmount ||
    !a.outputAmount ||
    !b.outputAmount
  ) {
    return -1
  }
  const ioComp = inputOutputComparator(a as any, b as any)
  if (ioComp !== 0) {
    return ioComp
  }

  // consider lowest slippage next, since these are less likely to fail
  if (a.priceImpact.lessThan(b.priceImpact)) {
    return -1
  } else if (a.priceImpact.greaterThan(b.priceImpact)) {
    return 1
  }

  // finally consider the number of hops since each hop costs gas
  return a.route.path.length - b.route.path.length
}

export interface BestTradeOptions {
  // how many results to return
  maxNumResults?: number
  // the maximum number of hops a trade should contain
  maxHops?: number
}

/**
 * Given a currency amount and a chain ID, returns the equivalent representation as the token amount.
 * In other words, if the currency is ETHER, returns the WETH token amount for the given chain. Otherwise, returns
 * the input currency amount.
 */
function wrappedAmount(
  currencyAmount: CurrencyAmount,
  chainId: ChainId
): TokenAmount {
  if (currencyAmount instanceof TokenAmount) return currencyAmount
  if (currencyAmount.currency === ETHER)
    return new TokenAmount(WETH[chainId], currencyAmount.raw)
  invariant(false, 'CURRENCY')
}

function wrappedCurrency(currency: Currency, chainId: ChainId): Token {
  if (currency instanceof Token) return currency
  if (currency === ETHER) return WETH[chainId]
  invariant(false, 'CURRENCY')
}

/**
 * Represents a trade executed against a list of pairs.
 * Does not account for slippage, i.e. trades that front run this trade and move the price.
 */
export class Trade {
  /**
   * The route of the trade, i.e. which pairs the trade goes through.
   */
  public route: Route
  /**
   * The type of the trade, either exact in or exact out.
   */
  public tradeType: TradeType
  public amount: CurrencyAmount
  /**
   * The input amount for the trade assuming no slippage.
   */
  public inputAmount: CurrencyAmount | undefined
  /**
   * The output amount for the trade assuming no slippage.
   */
  public outputAmount: CurrencyAmount | undefined
  /**
   * The price expressed in terms of output amount/input amount.
   */
  public executionPrice: Price | undefined
  /**
   * The mid price after the trade executes assuming no slippage.
   */
  // public nextMidPrice: Price | undefined
  /**
   * The percent difference between the mid price before the trade and the trade execution price.
   */
  public priceImpact: Percent | undefined

  public slippage: number | undefined

  public priceUpdate: string[]

  public updateFee: number

  /**
   * Constructs an exact in trade with the given amount in and route
   * @param route route of the exact in trade
   * @param amountIn the amount being passed in
   */
  public static exactIn(route: Route, amountIn: CurrencyAmount): Trade {
    return new Trade(route, amountIn, TradeType.EXACT_INPUT)
  }

  /**
   * Constructs an exact out trade with the given amount out and route
   * @param route route of the exact out trade
   * @param amountOut the amount returned by the trade
   */
  public static exactOut(route: Route, amountOut: CurrencyAmount): Trade {
    return new Trade(route, amountOut, TradeType.EXACT_OUTPUT)
  }

  public constructor(
    route: Route,
    amount: CurrencyAmount,
    tradeType: TradeType
  ) {
    this.route = route
    this.tradeType = tradeType
    this.amount = amount
    this.priceUpdate = []
    this.updateFee = 0
    // this.init(route, amount, tradeType)
  }

  init = async (
    route: Route,
    amount: CurrencyAmount,
    tradeType: TradeType,
    account: string
  ) => {
    const amounts: TokenAmount[] = new Array(route.path.length)
    const nextPairs: Pair[] = new Array(route.pairs.length)
    let oPrice: number = 1
    let newPriceUpdate: string[] = []
    let newUpdateFee = 0
    if (tradeType === TradeType.EXACT_INPUT) {
      invariant(currencyEquals(amount.currency, route.input), 'INPUT')
      amounts[0] = wrappedAmount(amount, route.chainId)
      console.log('route: ', route)
      for (let i = 0; i < route.path.length - 1; i++) {
        const pair = route.pairs[i]
        const [outputAmount, nextPair, odPrice, qti, prices, updateFeeData] =
          await pair.getOutputAmountAsync(
            amounts[i],
            route.path,
            pair.chainId,
            pair.liquidityToken.address,
            account
          )

        amounts[i + 1] = outputAmount
        nextPairs[i] = nextPair
        if (
          route.chainId === ChainId.BASE_SEPOLIA ||
          route.chainId === ChainId.AURORA_TESTNET ||
          route.chainId === ChainId.BOBA_TESTNET ||
          route.chainId === ChainId.NEOX_MAINNET ||
          route.chainId === ChainId.U2U_MAINNET
        ) {
          oPrice *=
            (qti === 1 &&
              (nextPairs[0].token1.name === outputAmount.currency.name ||
                route.input === ETHER)) ||
            (qti === 0 &&
              (nextPairs[0].token0.name === amounts[i].currency.name ||
                route.output === ETHER))
              ? 1 / +odPrice
              : +odPrice
        } else {
          oPrice *=
            (qti === 1 &&
              (nextPairs[0].token1.name === outputAmount.currency.name ||
                route.input === ETHER)) ||
            (qti === 0 &&
              (nextPairs[0].token0.name === amounts[i].currency.name ||
                route.output === ETHER))
              ? +odPrice
              : 1 / +odPrice
        }
        newPriceUpdate = prices
        newUpdateFee = updateFeeData
      }
    } else {
      invariant(currencyEquals(amount.currency, route.output), 'OUTPUT')
      amounts[amounts.length - 1] = wrappedAmount(amount, route.chainId)
      for (let i = route.path.length - 1; i > 0; i--) {
        const pair = route.pairs[i - 1]
        const [inputAmount, nextPair, odPrice, qti, prices, updateFeeData] =
          await pair.getInputAmountAsync(
            amounts[i],
            route.path,
            pair.chainId,
            pair.liquidityToken.address,
            account
          )
        amounts[i - 1] = inputAmount
        nextPairs[i - 1] = nextPair
        if (
          route.chainId === ChainId.BASE_SEPOLIA ||
          route.chainId === ChainId.AURORA_TESTNET ||
          route.chainId === ChainId.BOBA_TESTNET ||
          route.chainId === ChainId.NEOX_MAINNET ||
          route.chainId === ChainId.U2U_MAINNET
        ) {
          oPrice *=
            (qti === 1 &&
              (nextPairs[0].token1.name === amounts[i].currency.name ||
                route.input === ETHER)) ||
            (qti === 0 &&
              (nextPairs[0].token0.name === inputAmount.currency.name ||
                route.output === ETHER))
              ? 1 / +odPrice
              : +odPrice
        } else {
          oPrice *=
            (qti === 1 &&
              (nextPairs[0].token1.name === amounts[i].currency.name ||
                route.input === ETHER)) ||
            (qti === 0 &&
              (nextPairs[0].token0.name === inputAmount.currency.name ||
                route.output === ETHER))
              ? +odPrice
              : 1 / +odPrice
        }
        newPriceUpdate = prices
        newUpdateFee = updateFeeData
      }
    }

    this.route = route
    this.tradeType = tradeType
    this.inputAmount =
      tradeType === TradeType.EXACT_INPUT
        ? amount
        : route.input === ETHER
        ? CurrencyAmount.ether(amounts[0].raw)
        : amounts[0]
    this.outputAmount =
      tradeType === TradeType.EXACT_OUTPUT
        ? amount
        : route.output === ETHER
        ? CurrencyAmount.ether(amounts[amounts.length - 1].raw)
        : amounts[amounts.length - 1]
    this.executionPrice = new Price(
      this.inputAmount.currency,
      this.outputAmount.currency,
      this.inputAmount.raw,
      this.outputAmount.raw
    )
    this.priceUpdate = newPriceUpdate
    // this.nextMidPrice = Price.fromRoute(new Route(nextPairs, route.input))
    // console.log(route.midPrice.raw.toFixed(2))

    this.slippage =
      (1 -
        +this.outputAmount.raw /
          10 ** this.outputAmount.currency.decimals /
          (+this.inputAmount.raw / 10 ** this.inputAmount.currency.decimals) /
          +oPrice) *
      100

    console.log('alo', +this.outputAmount.raw, +this.inputAmount.raw, +oPrice)

    // this.priceImpact = computePriceImpact(
    //   route.midPrice,
    //   this.inputAmount,
    //   this.outputAmount
    // )
    this.priceImpact = new Percent('0')
    this.updateFee = newUpdateFee
  }

  /**
   * Get the minimum amount that must be received from this trade for the given slippage tolerance
   * @param slippageTolerance tolerance of unfavorable slippage from the execution price of this trade
   */
  public minimumAmountOut(slippageTolerance: Percent): CurrencyAmount {
    if (!this.outputAmount) {
      return CurrencyAmount.ether('0')
    }
    invariant(!slippageTolerance.lessThan(ZERO), 'SLIPPAGE_TOLERANCE')
    if (this.tradeType === TradeType.EXACT_OUTPUT) {
      return this.outputAmount
    } else {
      const slippageAdjustedAmountOut = new Fraction(ONE)
        .add(slippageTolerance)
        .invert()
        .multiply(this.outputAmount.raw).quotient
      return this.outputAmount instanceof TokenAmount
        ? new TokenAmount(this.outputAmount.token, slippageAdjustedAmountOut)
        : CurrencyAmount.ether(slippageAdjustedAmountOut)
    }
  }

  /**
   * Get the maximum amount in that can be spent via this trade for the given slippage tolerance
   * @param slippageTolerance tolerance of unfavorable slippage from the execution price of this trade
   */
  public maximumAmountIn(slippageTolerance: Percent): CurrencyAmount {
    if (!this.inputAmount) {
      return CurrencyAmount.ether('0')
    }
    invariant(!slippageTolerance.lessThan(ZERO), 'SLIPPAGE_TOLERANCE')
    if (this.tradeType === TradeType.EXACT_INPUT) {
      return this.inputAmount
    } else {
      const slippageAdjustedAmountIn = new Fraction(ONE)
        .add(slippageTolerance)
        .multiply(this.inputAmount.raw).quotient
      return this.inputAmount instanceof TokenAmount
        ? new TokenAmount(this.inputAmount.token, slippageAdjustedAmountIn)
        : CurrencyAmount.ether(slippageAdjustedAmountIn)
    }
  }

  public static sleep(timeout: number) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(true)
      }, timeout)
    })
  }

  static getPath(input: Currency, pairs: Pair[]): Token[] | null {
    const path: Token[] = [
      input instanceof Token ? input : WETH[pairs[0].chainId]
    ]
    let error = false
    for (const [i, pair] of pairs.entries()) {
      const currentInput = path[i]

      if (
        !(currentInput.equals(pair.token0) || currentInput.equals(pair.token1))
      ) {
        error = true
      } else {
        const output = currentInput.equals(pair.token0)
          ? pair.token1
          : pair.token0
        path.push(output)
      }
    }

    return error ? null : path
  }

  /**
   * Given a list of pairs, and a fixed amount in, returns the top `maxNumResults` trades that go from an input token
   * amount to an output token, making at most `maxHops` hops.
   * Note this does not consider aggregation, as routes are linear. It's possible a better route exists by splitting
   * the amount in among multiple routes.
   * @param pairs the pairs to consider in finding the best trade
   * @param currencyAmountIn exact amount of input currency to spend
   * @param currencyOut the desired currency out
   * @param maxNumResults maximum number of results to return
   * @param maxHops maximum number of hops a returned trade can make, e.g. 1 hop goes through a single pair
   * @param currentPairs used in recursion; the current list of pairs
   * @param originalAmountIn used in recursion; the original value of the currencyAmountIn parameter
   * @param bestTrades used in recursion; the current list of best trades
   */
  public static async bestTradeExactIn(
    account: string,
    pairs: Pair[],
    currencyAmountIn: CurrencyAmount,
    currencyOut: Currency,
    { maxNumResults = 3, maxHops = 3 }: BestTradeOptions = {},
    // used in recursion.
    currentPairs: Pair[] = [],
    originalAmountIn: CurrencyAmount = currencyAmountIn,
    bestTrades: Trade[] = []
  ): Promise<Trade[]> {
    invariant(pairs.length > 0, 'PAIRS')
    invariant(maxHops > 0, 'MAX_HOPS')
    invariant(
      originalAmountIn === currencyAmountIn || currentPairs.length > 0,
      'INVALID_RECURSION'
    )
    const chainId: ChainId | undefined =
      currencyAmountIn instanceof TokenAmount
        ? currencyAmountIn.token.chainId
        : currencyOut instanceof Token
        ? currencyOut.chainId
        : undefined
    invariant(chainId !== undefined, 'CHAIN_ID')

    const amountIn = wrappedAmount(currencyAmountIn, chainId)
    const tokenOut = wrappedCurrency(currencyOut, chainId)
    for (let i = 0; i < pairs.length; i++) {
      const pair = pairs[i]
      // pair irrelevant
      if (
        !pair.token0.equals(amountIn.token) &&
        !pair.token1.equals(amountIn.token)
      )
        continue
      if (pair.reserve0.equalTo(ZERO) || pair.reserve1.equalTo(ZERO)) continue

      let amountOut: TokenAmount
      const path = Trade.getPath(currencyAmountIn.currency, [
        ...currentPairs,
        pair
      ])

      if (!path) {
        console.log('path null exact in')
        continue
      }
      try {
        ;[amountOut] = await pair.getOutputAmountAsync(
          amountIn,
          path,
          chainId,
          pair.liquidityToken.address,
          account
        )
      } catch (error) {
        // input too low
        if (error?.isInsufficientInputAmountError) {
          continue
        }
        throw error
      }
      // we have arrived at the output token, so this is the final trade of one of the paths
      if (amountOut.token.equals(tokenOut)) {
        const newRoute = new Route(
          [...currentPairs, pair],
          originalAmountIn.currency,
          currencyOut
        )
        const newTrade = new Trade(
          newRoute,
          originalAmountIn,
          TradeType.EXACT_INPUT
        )
        await newTrade.init(
          newRoute,
          originalAmountIn,
          TradeType.EXACT_INPUT,
          account
        )
        sortedInsert(bestTrades, newTrade, maxNumResults, tradeComparator)
      } else if (maxHops > 1 && pairs.length > 1) {
        const pairsExcludingThisPair = pairs
          .slice(0, i)
          .concat(pairs.slice(i + 1, pairs.length))

        // otherwise, consider all the other paths that lead from this token as long as we have not exceeded maxHops
        Trade.bestTradeExactIn(
          account,
          pairsExcludingThisPair,
          amountOut,
          currencyOut,
          {
            maxNumResults,
            maxHops: maxHops - 1
          },
          [...currentPairs, pair],
          originalAmountIn,
          bestTrades
        )
      }
    }

    return bestTrades
  }

  /**
   * similar to the above method but instead targets a fixed output amount
   * given a list of pairs, and a fixed amount out, returns the top `maxNumResults` trades that go from an input token
   * to an output token amount, making at most `maxHops` hops
   * note this does not consider aggregation, as routes are linear. it's possible a better route exists by splitting
   * the amount in among multiple routes.
   * @param pairs the pairs to consider in finding the best trade
   * @param currencyIn the currency to spend
   * @param currencyAmountOut the exact amount of currency out
   * @param maxNumResults maximum number of results to return
   * @param maxHops maximum number of hops a returned trade can make, e.g. 1 hop goes through a single pair
   * @param currentPairs used in recursion; the current list of pairs
   * @param originalAmountOut used in recursion; the original value of the currencyAmountOut parameter
   * @param bestTrades used in recursion; the current list of best trades
   */
  public static async bestTradeExactOut(
    account: string,
    pairs: Pair[],
    currencyIn: Currency,
    currencyAmountOut: CurrencyAmount,
    { maxNumResults = 3, maxHops = 3 }: BestTradeOptions = {},
    // used in recursion.
    currentPairs: Pair[] = [],
    originalAmountOut: CurrencyAmount = currencyAmountOut,
    bestTrades: Trade[] = []
  ): Promise<Trade[]> {
    invariant(pairs.length > 0, 'PAIRS')
    invariant(maxHops > 0, 'MAX_HOPS')
    invariant(
      originalAmountOut === currencyAmountOut || currentPairs.length > 0,
      'INVALID_RECURSION'
    )
    const chainId: ChainId | undefined =
      currencyAmountOut instanceof TokenAmount
        ? currencyAmountOut.token.chainId
        : currencyIn instanceof Token
        ? currencyIn.chainId
        : undefined
    invariant(chainId !== undefined, 'CHAIN_ID')

    const amountOut = wrappedAmount(currencyAmountOut, chainId)
    const tokenIn = wrappedCurrency(currencyIn, chainId)
    for (let i = 0; i < pairs?.length; i++) {
      const pair = pairs[i]
      // pair irrelevant
      if (
        !pair?.token0.equals(amountOut.token) &&
        !pair?.token1.equals(amountOut.token)
      )
        continue

      if (pair.reserve0.equalTo(ZERO) || pair.reserve1.equalTo(ZERO)) continue

      let amountIn: TokenAmount
      const path = Trade.getPath(currencyIn, [...currentPairs, pair])
      if (!path) {
        console.log('path null exact out')
        continue
      }
      try {
        ;[amountIn] = await pair.getInputAmountAsync(
          amountOut,
          path,
          chainId,
          pair.liquidityToken.address,
          account
        )
      } catch (error) {
        // not enough liquidity in this pair
        if (error.isInsufficientReservesError) {
          continue
        }
        throw error
      }
      // we have arrived at the input token, so this is the first trade of one of the paths
      if (amountIn.token.equals(tokenIn)) {
        const newRoute = new Route(
          [pair, ...currentPairs],
          currencyIn,
          originalAmountOut.currency
        )
        const newTrade = new Trade(
          newRoute,
          originalAmountOut,
          TradeType.EXACT_OUTPUT
        )
        await newTrade.init(
          newRoute,
          originalAmountOut,
          TradeType.EXACT_OUTPUT,
          account
        )
        sortedInsert(bestTrades, newTrade, maxNumResults, tradeComparator)
      } else if (maxHops > 1 && pairs.length > 1) {
        const pairsExcludingThisPair = pairs
          .slice(0, i)
          .concat(pairs.slice(i + 1, pairs.length))

        // otherwise, consider all the other paths that arrive at this token as long as we have not exceeded maxHops
        Trade.bestTradeExactOut(
          account,
          pairsExcludingThisPair,
          currencyIn,
          amountIn,
          {
            maxNumResults,
            maxHops: maxHops - 1
          },
          [pair, ...currentPairs],
          originalAmountOut,
          bestTrades
        )
      }
    }

    return bestTrades
  }
}
